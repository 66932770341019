import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const COLOR_PRIMARY = '#556cd6';
const COLOR_SECONDARY = '#19857b';

// create a theme instance
const theme = createTheme({
  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: 'highlight' },
          style: {
            backgroundColor: COLOR_PRIMARY,
            color: 'white',
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;

import isArray from 'lodash/isArray';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import size from 'lodash/size';

import { TObject } from 'types';

// removes falsey values: null, undefined, '', []
export const cleanObject = <T extends {}>(obj: T | TObject) =>
  omitBy(obj, v => isUndefined(v) || isNull(v) || v === '' || (isArray(v) && size(v) === 0));

export const safeParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
};

import { NextApiRequest, NextApiResponse } from 'next';
import { createContext } from 'react';
import { useSession } from 'next-auth/react';
import { Session } from 'next-auth';

import { TToken, IUserSignUp, TFC, TNullable } from 'types';
import { signOutUser, log } from 'utils';

type TUser = IUserSignUp['user'] | null;

export type TAuthProvider = {
  authLogout: (req?: NextApiRequest, res?: NextApiResponse) => void;
  isAuthenticated: boolean;
  session: TNullable<Session>;
  sessionStatus: 'loading' | 'authenticated' | 'unauthenticated';
  token: TToken;
  user: TUser;
};

export const AuthContext = createContext({} as TAuthProvider);

/**
 * Authentication context provider
 * usage: const { user, authSignIn, token } = useContext(AuthContext)
 */
export const AuthProvider: TFC = ({ children }) => {
  const { data: session, status } = useSession();
  const storedUser = session
    ? {
        email: session?.email || '',
        baseCurrency: session?.baseCurrency || '',
      }
    : null;

  const authLogout = async (req?: NextApiRequest, res?: NextApiResponse) => {
    log.debug('authLogout called');
    await signOutUser(req, res);
  };

  const context = {
    authLogout,
    isAuthenticated: !!session?.accessToken,
    session,
    sessionStatus: status,
    token: session?.accessToken || '',
    user: storedUser,
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

import { styled } from '@mui/system';

export const BodyBackground = styled('div')({
  height: '100%',
  left: '0px',
  minHeight: '100%',
  position: 'fixed',
  top: '0px',
  width: '100%',
  opacity: 0.4,

  // backgroundColor: '#e5e5f7',
  // backgroundImage: 'radial-gradient(#9097ac 0.5px, #e5e5f7 0.5px)',
  // backgroundSize: '10px 10px',

  // backgroundColor: '#e5e5f7',
  // backgroundImage:
  //   'linear-gradient(#c2d3d6 0.9px, transparent 0.9px), linear-gradient(to right, #c2d3d6 0.9px, #e5e5f7 0.9px)',
  // backgroundSize: '18px 18px',

  backgroundColor: '#e5e5f7',
  backgroundSize: '18px 18px',
  backgroundImage:
    'repeating-linear-gradient(to right, #c2d3d6, #c2d3d6 0.9px, #e5e5f7 0.9px, #e5e5f7)',
  zIndex: -1,
});

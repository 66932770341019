import { config } from 'utils/env';

const REST_BASE_URL = config.restBaseUrl;

export const REST_AUTH_SIGNIN = `${REST_BASE_URL}/auth/local/sign-in`;
export const REST_AUTH_SIGNOUT = `${REST_BASE_URL}/auth/local/sign-out`;
export const EP_SIGN_IN_SOCIAL = (provider: string) =>
  `${REST_BASE_URL}/auth/social/${provider}/sign-in`;
export const REST_USERS_SIGNUP = `${REST_BASE_URL}/users`;
export const REST_USERS_FORGOT_PASSWORD = `${REST_BASE_URL}/users/forgot-password`;
export const REST_USERS_RESET_PASSWORD = `${REST_BASE_URL}/users/reset-password`;
export const REST_ORDER_STATUSES = `${REST_BASE_URL}/order-statuses`;
export const REST_ORDERS = `${REST_BASE_URL}/orders`;
export const REST_ORDERS_ID = (id: string) => `${REST_BASE_URL}/orders/${id}`;
export const REST_ORDERS_DUPLICATE = (id: string) => `${REST_BASE_URL}/orders/${id}/duplicate`;
export const REST_PAYMENT_TYPES = `${REST_BASE_URL}/payment-types`;
export const REST_PAYMENT_TYPES_ID = (id: string) => `${REST_BASE_URL}/payment-types/${id}`;
export const REST_SHIPPING_CARRIERS = `${REST_BASE_URL}/shipping-carriers`;
export const REST_SHOPS = `${REST_BASE_URL}/shops`;
export const REST_SHOPS_ID = (id: string) => `${REST_BASE_URL}/shops/${id}`;
export const REST_ORDER_CATEGORIES = `${REST_BASE_URL}/order-categories`;
export const REST_ORDER_CATEGORIES_ID = (id: string) => `${REST_BASE_URL}/order-categories/${id}`;
export const REST_ORDER_UPLOAD_FILES = (id: string) => `${REST_BASE_URL}/orders/${id}/files`;
export const REST_ORDER_DELETE_FILE = (id: string, fileId: string) =>
  `${REST_BASE_URL}/orders/${id}/files/${fileId}`;
export const REST_ORDER_RENAME_FILE = (id: string, fileId: string) =>
  `${REST_BASE_URL}/orders/${id}/files/${fileId}/name`;
export const REST_LOGS = `${REST_BASE_URL}/logs`;
export const REST_COUPONS = `${REST_BASE_URL}/coupons`;
export const REST_COUPONS_ID = (id: string) => `${REST_BASE_URL}/coupons/${id}`;

import { IS_SERVER } from 'utils';

const getLocale = () => {
  const LOCALE_DEFAULT = 'sk-SK';
  const LOCALE = IS_SERVER ? LOCALE_DEFAULT : navigator.language || LOCALE_DEFAULT;
  return LOCALE;
};

export const roundUp = (value: number, decimals: number) => {
  const fractionSize = 10 ** decimals;
  return Math.ceil(value * fractionSize) / fractionSize;
};

export const withSpaces = (value: number) => {
  if (typeof value === 'undefined') return '';
  return new Intl.NumberFormat(getLocale()).format(value);
};

export const round = (value: number, decimals = 2) => {
  if (isNaN(value)) return 0;
  const fractionSize = 10 ** decimals;
  return Math.round(value * fractionSize) / fractionSize;
};

export const toPercent = (value: number, decimals = 2) => `${round(value * 100, decimals)} %`;

const localeToCurrency = {
  'en-US': 'USD',
  'en-GB': 'GBP',
  'de-DE': 'EUR',
  'fr-FR': 'EUR',
  'sk-SK': 'EUR',
  'cs-CZ': 'CZK',
  'hu-HU': 'HUF',
  'pl-PL': 'PLN',
  'ru-RU': 'RUB',
  'uk-UA': 'UAH',
  'zh-CN': 'CNY',
  'ja-JP': 'JPY',
  'ko-KR': 'KRW',
  'es-ES': 'EUR',
  'pt-PT': 'EUR',
  'it-IT': 'EUR',
  'nl-NL': 'EUR',
  'da-DK': 'DKK',
  'fi-FI': 'EUR',
  'sv-SE': 'SEK',
  'no-NO': 'NOK',
  'tr-TR': 'TRY',
  'ar-SA': 'SAR',
  'he-IL': 'ILS',
  'id-ID': 'IDR',
  'ms-MY': 'MYR',
  'th-TH': 'THB',
  'vi-VN': 'VND',
};

const getCurrencyForLocale = (locale: string) => localeToCurrency[locale] || 'EUR';

export const toCurrency = (value = 0, decimals = 1) => {
  if (!value) return 0;
  const currency = getCurrencyForLocale(getLocale());
  return new Intl.NumberFormat(getLocale(), {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const toBytes = (value: number, decimals = 1) => {
  if (!value) return 0;
  return formatBytes(value, decimals);
};

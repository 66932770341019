import { IAuthSignIn, IAuthSignOut } from 'types';

import * as EP from './endpoints';
import { fetcherPost } from './fetcher';

export const restSignInLocal = (email: string, password: string) =>
  fetcherPost<IAuthSignIn>(EP.REST_AUTH_SIGNIN, {
    email,
    password,
  });

export const restSignOut = () => fetcherPost<IAuthSignOut>(EP.REST_AUTH_SIGNOUT);

// fb & google sign-in
export const restSignInSocial = (profile: any, provider: string, accessToken?: string) =>
  fetcherPost<IAuthSignIn>(EP.EP_SIGN_IN_SOCIAL(provider), { profile, accessToken });

import { NextApiRequest } from 'next';

import { TObject, TNullable } from 'types';

import { IS_SERVER } from './common.utils';

export const getBrowserInfo = (req?: NextApiRequest) => {
  const userAgentSSR = req?.headers['user-agent'];
  if (userAgentSSR)
    return {
      userAgent: userAgentSSR,
      language: req?.headers['accept-language'],
    };
  if (!IS_SERVER || !navigator) return null;
  return {
    appName: navigator?.appName,
    language: navigator?.language,
    platform: navigator?.platform,
    product: navigator?.product,
    userAgent: navigator?.userAgent,
    vendor: navigator?.vendor,
  };
};

export const encodeData = (data: TObject): string => {
  try {
    const jsonString = JSON.stringify(data);
    const encodedString = btoa(jsonString);
    return encodedString;
  } catch (error) {
    return '';
  }
};

export const decodeData = <T>(encodedString: string): TNullable<TObject<T>> => {
  try {
    const decodedString = atob(encodedString);
    const decodedObj = JSON.parse(decodedString);
    return decodedObj;
  } catch (error) {
    return null;
  }
};

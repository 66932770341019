
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import * as React from 'react';
import { SWRConfig } from 'swr';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import { BodyBackground } from 'components/atoms/BodyBackground';
import { ActivityIndicatorProvider, AuthProvider, NotificationProvider } from 'providers';
import { createEmotionCache } from 'styles/emotionCache';
import theme from 'styles/theme';
import 'styles/globals.css';

// mui: client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface IAppProps extends AppProps<{ session: Session }> {
  emotionCache?: EmotionCache;
}

const App: React.FC<IAppProps> = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Shopaholic Diary</title>
        <meta name='description' content='Ultimate E-Purchase Tracker' />
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <meta name='description' content='Save your orders as you go' />
        <link rel='icon' type='image/png' sizes='192x192' href='/favicon/favicon-android.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-browser.png' />
        <link rel='apple-touch-icon' sizes='180x180' href='/favicon/favicon-iphone.png' />
      </Head>
      <>
        <Script
          strategy='afterInteractive'
          id='gTag'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K2D88FM');`,
          }}
        />
        <ThemeProvider theme={theme}>
          <SWRConfig
            value={{
              revalidateIfStale: true,
              revalidateOnFocus: false,
              revalidateOnReconnect: true,
            }}
          >
            <SessionProvider session={pageProps.session}>
              <AuthProvider>
                <ActivityIndicatorProvider>
                  <NotificationProvider>
                    <CssBaseline />
                    <BodyBackground />
                    <Component {...pageProps} />
                  </NotificationProvider>
                </ActivityIndicatorProvider>
              </AuthProvider>
            </SessionProvider>
          </SWRConfig>
        </ThemeProvider>
      </>
    </CacheProvider>
  );
};

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  
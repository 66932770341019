import { NextApiRequest, NextApiResponse } from 'next';
import { signOut } from 'next-auth/react';

import { restSignOut } from 'rest/rest.auth';
import { destroyOrdersStore } from 'stores';
import { log, deleteCookie, IS_SERVER, ROUTE_SIGN_IN } from 'utils';

export const redirectToSignInPage = (res?: NextApiResponse, callbackUrl?: string) => {
  log.debug('redirectToSignInPage:: called');
  const url =
    !IS_SERVER && callbackUrl ? `${ROUTE_SIGN_IN}?callbackUrl=${callbackUrl}` : ROUTE_SIGN_IN;
  return IS_SERVER && res ? res?.redirect(url) : signOut({ callbackUrl: url });
};

export const signOutUser = async (req?: NextApiRequest, res?: NextApiResponse) => {
  log.debug('signOutUser:: called');
  try {
    const result = await restSignOut();
    destroyOrdersStore();
    deleteCookie('next-auth.session-token', { req, res });
    deleteCookie('next-auth.callback-url', { req, res });
    deleteCookie('next-auth.csrf-token', { req, res });
    signOut({ callbackUrl: ROUTE_SIGN_IN });
    log.debug('signOutUser:: user signed out');
    return result;
  } catch (error) {
    log.debug('signOutUser:: error: ', error);
  }
};

import truncate from 'lodash/truncate';

import { TNullable } from 'types';

export const toSeparatedList = (arr: string[], separator = ' | ') => arr?.join(separator);

export const urlPrettify = (url: TNullable<string> = '', length = 32) =>
  isValidUrl(url)
    ? truncate(url || '', { length: length }).replace(/https:\/\/|http:\/\//, ' ')
    : '';

export const isValidUrl = (value: TNullable<string> = '') => {
  const res = value?.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { TFnWithArgs } from 'types';

interface IOwnProps {
  active: boolean;
  onClose?: TFnWithArgs;
}

export const ActivityIndicator: React.FC<IOwnProps> = ({ onClose, active }) => {
  const handleClose = () => onClose?.();

  return active ? (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={active}
        onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  ) : null;
};

import {
  differenceInDays,
  differenceInSeconds,
  differenceInMilliseconds,
  endOfDay,
  startOfDay,
  endOfWeek,
  startOfWeek,
  endOfMonth,
  startOfMonth,
  endOfYear,
  startOfYear,
  format as dateFnsFormat,
  isAfter,
  isBefore,
  isValid,
  parse,
  getYear as getYearFns,
} from 'date-fns';
import isNull from 'lodash/isNull';

import { TDate, TNullable } from 'types';

export const DATE_FORMAT_BASE = 'dd.MM.yyyy';
export const DATE_FORMAT_YEAR_MONTH = 'yyyyMM';
export const DATE_FORMAT_MONTH_YEAR = 'MM/yyyy';
export const DATE_FORMAT_DATE_TIME = 'dd.MM.yyyy HH:mm';
export const DATE_FORMAT_FULL = 'dd.MM.yyyy HH:mm:ss';

export const now = (): Date => new Date();

export const nowISO = (): string => new Date().toISOString();

export const toISO = (date: TDate): string => new Date(date).toISOString();

export const isValidDate = (date?: TNullable<TDate>): boolean =>
  date && !isNull(date) ? isValid(new Date(date)) : false;

export const isValidStringDate = (date: string, format: string = DATE_FORMAT_BASE): boolean =>
  isValid(parse(date, format, new Date()));

export const formatDate = (
  date?: TNullable<TDate>,
  dateFormat: string = DATE_FORMAT_BASE
): string | null =>
  date && !isNull(date) && isValidDate(date) ? dateFnsFormat(new Date(date), dateFormat) : null;

export const getDaysDiff = (startDate: TDate, endDate: TDate): number =>
  differenceInDays(new Date(startDate), new Date(endDate));

export const getSecondsDiff = (startDate: TDate, endDate: TDate): number =>
  differenceInSeconds(new Date(startDate), new Date(endDate));

export const getMillisecondsDiff = (startDate: TDate, endDate: TDate): number =>
  differenceInMilliseconds(new Date(startDate), new Date(endDate));

export const isAfterToday = (value: TDate): boolean =>
  isValidDate(value) && isAfter(endOfDay(now()), endOfDay(new Date(value)));

export const isAfterTargetDate = (value: TDate, targetDate: TDate): boolean =>
  isValidDate(value) &&
  isValidDate(targetDate) &&
  isAfter(endOfDay(new Date(targetDate)), endOfDay(new Date(value)));

export const parseStringDate = (date: string, format: string) => parse(date, format, new Date());

export const getUnixDate = (date: string, format: string) =>
  parseStringDate(date, format).getTime();

export const formatStringDate = (date: string, inputFormat: string, outputFormat: string) =>
  date && dateFnsFormat(parseStringDate(date, inputFormat), outputFormat);

export const isBeforeDate = (dateToCompare: TDate, beforeDate: TDate): boolean =>
  isValidDate(dateToCompare) &&
  isValidDate(beforeDate) &&
  isBefore(new Date(dateToCompare), new Date(beforeDate));

export const getYear = (value = new Date()) => getYearFns(value);

export const getSinceTo = () => {
  const _now = now();
  const result = {
    today: {
      since: toISO(startOfDay(_now)),
      to: toISO(endOfDay(_now)),
    },
    week: {
      since: toISO(startOfWeek(_now)),
      to: toISO(endOfWeek(_now)),
    },
    month: {
      since: toISO(startOfMonth(_now)),
      to: toISO(endOfMonth(_now)),
    },
    year: {
      since: toISO(startOfYear(_now)),
      to: toISO(endOfYear(_now)),
    },
  };
  return result;
};

export const parseDate = (value: string, format: string) => parse(value, format, new Date());

export const convertYearMonth = (value: number, format = DATE_FORMAT_MONTH_YEAR): string =>
  formatDate(parseDate(String(value), DATE_FORMAT_YEAR_MONTH), format) || '';

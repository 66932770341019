import create from 'zustand';

import { EFilterMenuNames, IOrder, IOrderResponse, IOrdersSummary, TNullable } from 'types';

interface IOrdersState {
  filterVisible: boolean;
  filterMenu: TNullable<EFilterMenuNames>; // selected filter menu option
  setFilterMenu: (name: TNullable<EFilterMenuNames>) => void;
  setFilterVisible: (value: boolean) => void;
  selectedOrderIds: string[];
  setSelectedOrderIds: (ids: string[]) => void;
  loadedOrders: IOrder[];
  setLoadedOrders: (orders: IOrder[]) => void;
  totalDocs: number;
  setTotalDocs: (value: number) => void;
  stats: IOrderResponse['stats'] | null;
  setStats: (value: IOrderResponse['stats'] | null) => void;
  ordersSummary: TNullable<IOrdersSummary>;
  setOrdersSummary: (value: TNullable<IOrdersSummary>) => void;
}

/**
 * Orders store
 * usage:
 *
 * import { useOrdersStore } from 'stores';
 * const { filterMenu, setFilterMenu } = useOrdersStore();
 *
 */
export const useOrdersStore = create<IOrdersState>(set => ({
  filterVisible: false,
  filterMenu: null,
  setFilterMenu: (name: TNullable<EFilterMenuNames>) => set(() => ({ filterMenu: name })),
  setFilterVisible: (value: boolean) => set(() => ({ filterVisible: value })),
  selectedOrderIds: [],
  setSelectedOrderIds: (ids: string[]) => set(() => ({ selectedOrderIds: ids })),
  loadedOrders: [],
  setLoadedOrders: (orders: IOrder[]) => set(() => ({ loadedOrders: orders })),
  totalDocs: 0,
  setTotalDocs: (value: number) => set(() => ({ totalDocs: value })),
  stats: null,
  setStats: (value: IOrderResponse['stats'] | null) => set(() => ({ stats: value })),
  ordersSummary: null,
  setOrdersSummary: (value: TNullable<IOrdersSummary>) => set(() => ({ ordersSummary: value })),
}));

export const destroyOrdersStore = () => useOrdersStore.destroy();

import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { createContext } from 'react';

import { TFC } from 'types';

export type TNotificationProvider = {
  showNotification: (text: string, severity: AlertColor, alertKey?: string) => void;
  notifySuccess: (text: string, alertKey?: string) => void;
  notifyError: (text: string, alertKey?: string) => void;
  notifyInfo: (text: string, alertKey?: string) => void;
  notifyWarning: (text: string, alertKey?: string) => void;
};

export const NotificationContext = createContext({} as TNotificationProvider);

export const NotificationProvider: TFC = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [alertKey, setAlertKey] = React.useState<string>('');
  const [severity, setSeverity] = React.useState<AlertColor>('info');

  const showNotification = (text: string, notifSeverity: AlertColor, key = '') => {
    setMessage(text);
    setSeverity(notifSeverity);
    setOpen(true);
    setAlertKey(key);
  };

  const setNotificationState = (text: string, key = '', isOpen = true) => {
    setMessage(text);
    setOpen(isOpen);
    setAlertKey(key);
  };

  const notifySuccess = (text: string, key = '') => {
    setSeverity('success');
    setNotificationState(text, key);
  };

  const notifyError = (text: string, key = '') => {
    setSeverity('error');
    setNotificationState(text, key);
  };

  const notifyWarning = (text: string, key = '') => {
    setSeverity('warning');
    setNotificationState(text, key);
  };

  const notifyInfo = (text: string, key = '') => {
    setSeverity('info');
    setNotificationState(text, key);
  };

  const hideNotification = () => setNotificationState('', '', false);

  const handleClose = () => hideNotification();

  return (
    <NotificationContext.Provider
      value={{ showNotification, notifySuccess, notifyError, notifyWarning, notifyInfo }}
    >
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        key={alertKey}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

import clsx, { ClassValue } from 'clsx';
import startsWith from 'lodash/startsWith';

export const mergeClasses = (...classes: ClassValue[]) => clsx(...classes);

export const IS_SERVER = typeof window === 'undefined';

export const textToUrl = (value: string) => {
  if (!value) return '';
  const isUrl = startsWith(value, 'http');
  if (isUrl) return value;
  return `https://${value}`;
};

export const copyToClipboard = text => navigator.clipboard.writeText(text);

import { setCookie as setCookieNext } from 'cookies-next';
import { deleteCookie as deleteCookieNext } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import { getCookie as getCookieNext } from 'cookies-next';
import { safeParse } from './object.utils';

export const setCookie = (key: string, data?: any, opts?: any) => setCookieNext(key, data, opts);

export const deleteCookie = (key: string, opts?: OptionsType) => deleteCookieNext(key, opts);

export const getCookie = (key: string, opts?: OptionsType, parseJson = true) => {
  const result = getCookieNext(key, opts);
  if (!result) return null;
  if (parseJson) return safeParse(result);
  return result;
};

import merge from 'lodash/merge';
import { NextApiRequest, NextApiResponse } from 'next';
import { getSession } from 'next-auth/react';

import { TObject, IApiError } from 'types';
import { log, signOutUser, redirectToSignInPage } from 'utils';

export const fetcher = async <T = any>(
  input: RequestInfo,
  initConfig?: RequestInit,
  req?: NextApiRequest,
  res?: NextApiResponse
): Promise<T | any> => {
  try {
    const session = await getSession({ req });
    const token = session?.accessToken;
    const config = merge(initConfig, {
      headers: {
        token,
      },
    });
    log.debug('fetcher:: input', input);
    log.debug('fetcher:: config', config);
    const response = await fetch(input, config);
    log.debug('fetcher:: res.status', response.status, input);

    if (response.status === 401) {
      log.debug('fetcher:: 401!', token);
      if (!token) {
        log.debug('fetcher:: no token, redirecting');
        redirectToSignInPage(res);
        return;
      }
      await signOutUser(req, res);
      log.debug('signOutUser');
      Promise.resolve(null);
      log.debug('fetcher:: 401: auto sign-out done');
      return;
    }

    if (response.status === 204) {
      log.debug('fetcher:: 204!');
      return Promise.resolve('OK');
    }

    if (response.status === 404) {
      log.debug('fetcher:: 404!');
      return Promise.resolve(null);
    }

    if (response.ok) {
      log.debug('fetcher:: response.ok');
      return response.json();
    }

    const jsonResult = await response.json();
    const responseError: IApiError = {
      message: jsonResult.message || 'Something went wrong',
      status: jsonResult.status || '',
      code: jsonResult.code || '',
    };
    log.error('fetcher:: responseError: ', responseError);
    let error = new Error();
    error = { ...error, ...responseError };
    throw error;
  } catch (err) {
    log.error('fetcher:: err: ', err);
    return Promise.reject(err);
  }
};

export const fetcherPost = async <T extends {}>(
  url: string,
  data?: unknown,
  initConfig?: RequestInit,
  req?: NextApiRequest,
  res?: NextApiResponse
): Promise<T> =>
  fetcher<T>(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      ...initConfig,
    },
    req,
    res
  );

export const fetcherPut = async <T extends {}>(
  url: string,
  data?: any,
  initConfig?: RequestInit,
  contentType = 'application/json',
  req?: NextApiRequest,
  res?: NextApiResponse
): Promise<T> =>
  fetcher<T>(
    url,
    {
      method: 'PUT',
      headers: {
        ...(!!contentType && { 'Content-Type': contentType }),
      },
      body: !!contentType ? JSON.stringify(data) : data,
      ...initConfig,
    },
    req,
    res
  );

export const fetcherDelete = async <T extends {}>(
  url: string,
  initConfig?: RequestInit,
  req?: NextApiRequest,
  res?: NextApiResponse
): Promise<T> =>
  fetcher<T>(
    url,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      ...initConfig,
    },
    req,
    res
  );

export const fetcherGet = async <T extends {}>(
  url: string,
  initConfig?: RequestInit,
  req?: NextApiRequest,
  res?: NextApiResponse
): Promise<T> =>
  fetcher<T>(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      ...initConfig,
    },
    req,
    res
  );

export const fetcherGetWithParams = async <T extends {}>(
  url: string,
  params: TObject<string>,
  initConfig?: RequestInit,
  req?: NextApiRequest,
  res?: NextApiResponse
): Promise<T> =>
  fetcher<T>(
    url + new URLSearchParams(params),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      ...initConfig,
    },
    req,
    res
  );

import { createContext, useState } from 'react';

import { ActivityIndicator } from 'components/molecules/ActivityIndicator';
import { TFC } from 'types';

export interface IActivityIndicatorContext {
  active: boolean;
  showIndicator: () => void;
  hideIndicator: () => void;
}

export const ActivityIndicatorContext = createContext({} as IActivityIndicatorContext);

/**
 * Activity indicator provider
 * usage: const { showIndicator } = useContext(ActivityIndicatorContext)
 */
export const ActivityIndicatorProvider: TFC = ({ children }) => {
  const [active, setActive] = useState(false);

  const showIndicator = () => setActive(true);

  const hideIndicator = () => setActive(false);

  const context = { active, showIndicator, hideIndicator };

  return (
    <ActivityIndicatorContext.Provider value={context}>
      <ActivityIndicator active={active} onClose={hideIndicator} />
      {children}
    </ActivityIndicatorContext.Provider>
  );
};

import get from 'lodash-es/get';

export const config = {
  restBaseUrl: process.env.NEXT_PUBLIC_REST_BASE_URL || 'http://localhost:3333/api/v1',
  env: process.env.NODE_ENV,
};

export const ENV = {
  prod: 'prod',
  local: 'local',
};

export const getEnv = (): string => {
  if (typeof window === 'undefined') return '';
  if (!window.location) return '';

  const location = get(window.location, 'host', '');
  const isProd = location === 'shopaholic-diary.com';
  const isLocal = location.indexOf('localhost') > -1;

  if (isProd) return ENV.prod;
  if (isLocal) return ENV.local;
  return '';
};

export const isProdEnv = getEnv() === ENV.prod;
export const isLocalEnv = getEnv() === ENV.local;

export const ENV_DEV = config.env === 'development';
export const ENV_PROD = config.env === 'production';

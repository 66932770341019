import Router from 'next/router';
import { stringify } from 'qs';

import { TObject } from 'types';
import { IS_SERVER, log } from 'utils';

import { cleanObject } from './object.utils';

export const ROUTE_SIGN_IN = '/sign-in';
export const ROUTE_SIGN_UP = '/sign-up';
export const ROUTE_SIGN_OUT = '/sign-out';
export const ROUTE_HOME = '/';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_ORDER = '/order';
export const ROUTE_STATS = '/stats';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_COUPONS = '/coupons';

export const addQueryParam = (query: TObject<string | number>) => {
  if (IS_SERVER) return;
  return Router.replace({
    query: {
      ...Router.query,
      ...query,
    },
  });
};

export const removeQueryParam = (param: string) => {
  if (IS_SERVER) return;
  const { pathname, query } = Router;
  const params = new URLSearchParams(query as any);
  params.delete(param);
  Router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
};

export const navigateTo = (
  route = ROUTE_DASHBOARD,
  locale = 'en',
  as?: string,
  opts = { locale: 'en' }
) => {
  const to = locale === 'en' ? route : `/${locale}${route}`;
  if (locale) opts.locale = locale;
  log.debug('navigateTo:: route', to);
  return Router.push(to, as, opts);
};

export const navigateToOrder = (id: string | null, newTab = false) => {
  if (!id) return;
  const asPath = Router.asPath;
  const hasQueryParams = asPath.includes('?');
  const queryParams = hasQueryParams ? asPath.substring(asPath.indexOf('?')) : '';
  const URL = hasQueryParams ? `/order/${id}${queryParams}` : `/order/${id}`;
  newTab ? openInTab(URL) : navigateTo(URL);
};

export const generateUrlWithSearch = (route = ROUTE_DASHBOARD) => {
  const asPath = Router.asPath;
  const hasQueries = asPath.indexOf('?') >= 0;
  if (!hasQueries) return route;
  const searchQuery = hasQueries ? asPath.substring(asPath.indexOf('?')) : '';
  const params = new URLSearchParams(searchQuery);
  const paramsStr = params.toString() !== '' ? `?${params.toString()}` : '';
  const to = `${route}${paramsStr}`;
  return decodeURIComponent(to);
};

/**
 * Navigate to route and keep URL query params
 * source: https://stackoverflow.com/a/70464842
 */
export const navigateWithParams = (route = ROUTE_DASHBOARD, opts?: any) => {
  const to = generateUrlWithSearch(route);
  log.debug('navigateWithParams:: to', to);
  return Router.push(to, to, opts);
};

export const routeReplace = (route = ROUTE_DASHBOARD, as?: string, opts?: any) =>
  Router.replace(route, as, opts);

export const getQueryVariable = (key: string) => {
  if (IS_SERVER) return;
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == key) return decodeURIComponent(pair[1]);
  }
};

export const getUrlQuery = (queryObj: TObject) => stringify(cleanObject(queryObj));

export const openInTab = (url: string) => url && window.open(url, '_blank');
